import React from 'react';

import { graphRequest } from 'services/contentful';

import { fragment as cardTestimonialFragment } from 'components/cards/CardTestimonial/fragment';

import {
    assetFragment,
    ctaFragment,
    iconFragment,
    linkFragment,
} from 'lib/graphql/fragments';

import BlockGrid from './BlockGrid';

const query = /* GraphQL */ `
    ${assetFragment}
    ${ctaFragment}
    ${linkFragment}
    ${iconFragment}
    ${cardTestimonialFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockGrid(preview: $preview, id: $id) {
            __typename
            anchorId
            flushBottom
            flushBottomMobile
            flushTop
            roundedBottom
            roundedTop
            fullWidth
            backgroundColor
            ctaCard {
                headline
                cta {
                    ... on Cta {
                        ...CTA
                    }
                }
                icon {
                    ... on Icon {
                        ...Icon
                    }
                }
            }
            gridRowCollection {
                items {
                    headline
                    richHeadline {
                        json
                    }
                    eyebrow
                    number
                    unit
                    image {
                        ...Asset
                    }
                    imageLarge {
                        ...Asset
                    }
                    leftImage
                    bodyCopy
                    richText {
                        json
                    }
                    cta {
                        ... on Cta {
                            ...CTA
                        }
                    }
                    icon {
                        id
                        title
                        icon {
                            ...Asset
                        }
                    }
                    iconType
                    blueBackground
                    theme
                    textTheme
                    testimonial {
                        ...CardTestimonialFragment
                    }
                }
            }
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockGrid },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });

    return blockGrid;
}

const BlockGridServer = async ({ id }) => {
    const block = await fetchBlock(id);

    return (
        <BlockGrid
            {...block}
            ctaCard={block.ctaCard}
            gridRow={block.gridRowCollection.items}
        />
    );
};

export default BlockGridServer;
