import React from 'react';

import { graphRequest } from 'services/contentful';

import { assetFragment } from 'lib/graphql/fragments';

import BlockImage from './BlockImage';

const query = /* GraphQL */ `
    ${assetFragment}
    query getBlock($id: String!, $preview: Boolean!) {
        blockImage(preview: $preview, id: $id) {
            __typename
            anchorId
            image {
                ...Asset
            }
            imageLarge {
                ...Asset
            }
            flushTop
            flushBottom
            fullWidth
            roundedTop
            roundedBottom
            backgroundColor
        }
    }
`;

async function fetchBlock(id) {
    const {
        body: {
            data: { blockImage },
        },
    } = await graphRequest({
        query,
        variables: { id },
    });
    return blockImage;
}

const BlockImageServer = async ({ id, ...rest }) => {
    const block = await fetchBlock(id);
    return <BlockImage {...block} {...rest} />;
};

export default BlockImageServer;
