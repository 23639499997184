'use client';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import Portal from 'components/ui/Portal';
import Overlay from 'components/ui/Portal/Overlay';
import SvgIcon from 'components/ui/SvgIcon';

import styles from './VideoPlayerYT.module.scss';

const VideoPlayerYTPortal = ({
    headline,
    youtubeVideoId,
    isOpen,
    handleClose,
}) => {
    return (
        <Portal>
            <Overlay isOpen={isOpen} handleClose={handleClose}>
                <div
                    className={cx(styles.videoOverlay, {
                        [styles.isOpen]: isOpen,
                    })}
                >
                    <div
                        className={styles.videoOverlayBackdrop}
                        onClick={handleClose}
                        role="presentation"
                    />
                    <div
                        className={styles.videoOverlayClose}
                        onClick={handleClose}
                    >
                        <Button
                            className={styles.videoOverlayCloseIcon}
                            theme="close"
                            onClick={handleClose}
                        >
                            <SvgIcon type="close" />
                            <span className="sr-only">
                                Close Video Overlay - {headline}
                            </span>
                        </Button>
                    </div>
                    <div className={styles.videoOverlayInnerWrap}>
                        <div
                            className={cx(
                                styles.videoOverlayBackdrop,
                                styles.transparent
                            )}
                            onClick={handleClose}
                            role="presentation"
                        />
                        <div className={styles.videoOverlayInner}>
                            {isOpen && (
                                <iframe
                                    allow="autoplay *; fullscreen *; encrypted-media"
                                    allowFullScreen
                                    frameBorder="0"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    className={styles.iFrame}
                                    src={`https://www.youtube-nocookie.com/embed/${youtubeVideoId}?autoplay=1&origin=https://www.tillamook.com/`}
                                    data-ot-ignore
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Overlay>
        </Portal>
    );
};

VideoPlayerYTPortal.propTypes = {
    headline: PropTypes.string,
    youtubeVideoId: PropTypes.string,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default VideoPlayerYTPortal;
