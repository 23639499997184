'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

import cx from 'clsx';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';

import styles from './Slideshow.module.scss';

const timerDuration = 5000;

const Slideshow = ({ images, className, theme = 'light' }) => {
    const rootRef = useRef(null);
    const timerRef = useRef(0);
    const [isMounted, setIsMounted] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [previousIndex, setPreviousIndex] = useState(0);

    const validImages = images?.filter(img => !!img.url) ?? [];
    const isSlideshow = validImages.length > 1;

    const intersection = useIntersection(rootRef, {
        root: null,
        threshold: 0,
    });

    const next = useCallback(
        isClick => {
            setActiveIndex(prevIndex => {
                setPreviousIndex(prevIndex);
                return prevIndex < validImages.length - 1 ? prevIndex + 1 : 0;
            });

            if (isClick) {
                clearTimeout(timerRef.current);
            } else {
                timerRef.current = setTimeout(() => {
                    next();
                }, timerDuration);
            }
        },
        [validImages.length]
    );

    const handleImageClick = () => {
        next(true);
    };

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (intersection?.isIntersecting) {
            timerRef.current = setTimeout(() => {
                next(false);
            }, timerDuration);
        } else {
            clearTimeout(timerRef.current);
        }

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [intersection, next]);

    return (
        <div
            ref={rootRef}
            className={cx(styles.root, className, styles[theme], {
                [styles.isSlideshow]: isSlideshow,
            })}
        >
            <div
                className={styles.imageWrapper}
                onClick={isSlideshow ? handleImageClick : null}
            >
                {validImages.map((image, idx) => {
                    return (
                        <Img
                            key={idx}
                            className={cx(styles.image, {
                                [styles.isMounted]: isMounted,
                                [styles.isActive]: activeIndex === idx,
                                [styles.isPrevious]: previousIndex === idx,
                            })}
                            src={image.url}
                            fallbackImageWidth={768}
                            alt={image?.description || ''}
                            width={image?.width}
                            height={image?.height}
                            customSources={[
                                {
                                    breakpoint: 1920,
                                    src: image.url,
                                    imageWidth: 1920 * 0.75,
                                },
                                {
                                    breakpoint: 1440,
                                    src: image.url,
                                    imageWidth: 1440 * 0.75,
                                },
                                {
                                    breakpoint: 1024,
                                    src: image.url,
                                    imageWidth: 1024 * 0.75,
                                },
                                {
                                    breakpoint: 768,
                                    src: image.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: image.url,
                                    imageWidth: 768,
                                },
                            ]}
                            priority
                        />
                    );
                })}
            </div>
            {isSlideshow && (
                <nav className={styles.nav}>
                    <div className={styles.dots}>
                        {validImages?.map((image, idx) => {
                            return (
                                <div
                                    key={idx}
                                    role="button"
                                    className={cx(styles.dot, {
                                        [styles.isActive]: activeIndex === idx,
                                    })}
                                    onClick={() => setActiveIndex(idx)}
                                />
                            );
                        })}
                    </div>
                </nav>
            )}
        </div>
    );
};

Slideshow.propTypes = {
    images: PropTypes.array,
    className: PropTypes.string,
    theme: PropTypes.string,
};

export default Slideshow;
