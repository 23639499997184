import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

/* Cards */
import CardAnimatedIcon from 'components/cards/CardAnimatedIcon';
import CardCampaign from 'components/cards/CardCampaign';
import CardDownload from 'components/cards/CardDownload';
import CardDualAspectNews from 'components/cards/CardDualAspectNews';
import CardIconStat from 'components/cards/CardIconStat';
import CardLatest from 'components/cards/CardLatest';
import CardLeadership from 'components/cards/CardLeadership';
import CardNewsDetail from 'components/cards/CardNewsDetail';
import CardPolaroid from 'components/cards/CardPolaroid';
import CardProduct from 'components/cards/CardProduct';
import CardRecipe from 'components/cards/CardRecipe';
import CardSmall from 'components/cards/CardSmall';
import ButtonCTA from 'components/ui/ButtonCTA';
import RichText, { BLOCKS } from 'components/ui/RichText';
import Text from 'components/ui/Text';
import TextEyebrow from 'components/ui/TextEyebrow';

import { blockRootProps, getColor } from 'utils';

import CardGroupCarousel from './CardGroupCarousel';
import InlineIntro from './InlineIntro';

import styles from './BlockCardGroup.module.scss';

const themeConfig = {
    'Full Width': {
        className: 'fullWidth',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Featured: {
        className: 'featured',
        buttonTheme: 'outlined-cream-cream',
        eyebrowTheme: 'display',
    },
    Default: {
        className: 'default',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Leadership: {
        className: 'leadership',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Latest: {
        className: 'latest',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Events: {
        className: 'events',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Campaign: {
        className: 'campaign',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Recipes: {
        className: 'recipes',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'displayXSmall',
    },
    Products: {
        className: 'products',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'displayXSmall',
    },
    Recommended: {
        className: 'recommended',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Download: {
        className: 'download',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'display',
    },
    Ultrabold: {
        className: 'ultrabold',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'ultrabold',
    },
    'Inline Intro': {
        className: 'inlineIntro',
        icon: 'none',
        buttonTheme: 'filled-blue-ocean',
        eyebrowTheme: 'ultrabold',
    },
    'Recipe Products': {
        className: 'recipeProducts',
        buttonTheme: 'outlined-blue-blue',
        eyebrowTheme: 'ultrabold',
    },
    BlockJams: {
        className: 'blockJams',
        eyebrowTheme: 'displayXSmall',
    },
    Holiday: {
        className: 'holiday',
    },
};

const richTextOverrides = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
            return (
                <Text
                    as="p"
                    baseTheme="bodyMedium"
                    themes={{ xxLarge: 'bodyLarge' }}
                    className={styles.copy}
                >
                    {children}
                </Text>
            );
        },
    },
};

const BlockCardGroup = ({
    __typename,
    anchorId,
    theme,
    eyebrow,
    headline,
    copy,
    cards,
    cta,
    flushTop,
    flushBottom,
    navigationTheme,
    backgroundColor,
    inlineIntroTextColor,
}) => {
    theme ??= 'Default';
    inlineIntroTextColor ??= 'blue';
    backgroundColor ??= 'cream';

    const cardMap = {
        CardAnimatedIcon,
        Recipe: CardRecipe,
        CardCampaign,
        Product: theme === 'Recipe Products' ? CardPolaroid : CardProduct,
        CardLeadership,
        CardDownload,
        CardDualAspectNews,
        CardLatest,
        News: CardNewsDetail,
        CardIconStat,
        CardSmall,
        CardPolaroid,
        InlineIntro,
        Default: null,
    };

    const inlineIntroPlaceholderCard = {
        __typename: 'InlineIntro',
        isPlaceholder: true,
        eyebrow,
        headline,
        copy,
        cta,
    };

    const inlineIntro = {
        eyebrow,
        headline,
        copy,
        cta,
        textColor: inlineIntroTextColor,
    };

    if (!cards) {
        return null;
    }

    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(
                styles.cardGroup,
                styles[themeConfig[theme]?.className],
                {
                    [styles.flushTop]: flushTop,
                    [styles.flushBottom]: flushBottom,
                }
            )}
            style={{
                '--background-color':
                    backgroundColor && getColor(backgroundColor),
                '--slider-gap-color': backgroundColor
                    ? getColor(backgroundColor)
                    : getColor('cream'),
                '--inline-intro-color':
                    inlineIntroTextColor && getColor(inlineIntroTextColor),
            }}
        >
            {theme === 'Inline Intro' && headline && (
                <div className={styles.inlineIntroSmall}>
                    {headline && (
                        <TextEyebrow
                            className={styles.eyebrow}
                            eyebrow={eyebrow}
                            headline={headline}
                            icon={themeConfig[theme]?.icon}
                            theme={themeConfig[theme]?.eyebrowTheme}
                        />
                    )}
                    {copy && (
                        <RichText
                            richText={copy}
                            overrides={richTextOverrides}
                        />
                    )}
                    {cta?.text && (cta?.link || cta?.assetDownloadLink) && (
                        <ButtonCTA
                            className={styles.cta}
                            theme="filled-cream-ocean"
                            text={cta.text}
                            {...cta}
                        />
                    )}
                </div>
            )}
            <div className={styles.cardGroupCarousel}>
                {theme === 'Inline Intro' && (
                    <div className={styles.inlineIntroContent}>
                        <div className={styles.inlineIntroContentInner}>
                            <InlineIntro {...inlineIntro} />
                        </div>
                    </div>
                )}
                <CardGroupCarousel
                    cardMap={cardMap}
                    cards={
                        theme === 'Inline Intro'
                            ? [inlineIntroPlaceholderCard, ...cards]
                            : cards
                    }
                    theme={theme}
                    navigationTheme={navigationTheme ?? 'blue'}
                    backgroundColor={backgroundColor}
                />
            </div>
            {theme !== 'Inline Intro' && cta?.link && cta?.text && (
                <ButtonCTA
                    className={styles.cta}
                    style={themeConfig[theme]?.buttonTheme}
                    text={cta.text}
                    {...cta}
                />
            )}
        </section>
    );
};

BlockCardGroup.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    theme: PropTypes.oneOf([
        'Default',
        'Leadership',
        'Latest',
        'Campaign',
        'Inline Intro',
        'Recipe Product',
    ]),
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    cards: PropTypes.array.isRequired,
    copy: PropTypes.object,
    cta: PropTypes.object,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    hasArrowIcon: PropTypes.bool,
    navigationTheme: PropTypes.oneOf(['blue', 'cream', 'orange']),
    backgroundColor: PropTypes.string,
    inlineIntroTextColor: PropTypes.string,
};

export default BlockCardGroup;
