import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import RichText from 'components/ui/RichText';

import { blockRootProps } from 'utils';

import styles from './BlockSimpleImageParagraph.module.scss';

const BlockSimpleImageParagraph = ({
    __typename,
    anchorId,
    image,
    richCopy,
    cta,
    className,
    flushBottom = true,
    flushTop = true,
}) => {
    return (
        <section
            {...blockRootProps(anchorId, __typename)}
            className={cx(styles.blockSimpleImageParagraph, className, {
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
        >
            <div className={styles.inner}>
                {image?.url && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={500}
                        alt={image.description || ''}
                        customSources={[
                            {
                                src: image.url,
                                imageWidth: 500,
                            },
                        ]}
                    />
                )}

                <div className={styles.content}>
                    <div className={styles.copy}>
                        {richCopy && <RichText richText={richCopy} />}
                    </div>
                    {cta?.link && (
                        <div className={styles.buttonWrapper}>
                            <ButtonCTA {...cta} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

BlockSimpleImageParagraph.propTypes = {
    __typename: PropTypes.string,
    anchorId: PropTypes.string,
    className: PropTypes.string,
    cta: PropTypes.object,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    richCopy: PropTypes.object,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
};

export default BlockSimpleImageParagraph;
