import React from 'react';

import cx from 'clsx';
import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';

import { blockRootProps } from 'utils';

import OverflowCarousel from './OverflowCarousel';

import styles from './BlockOverflowCarousel.module.scss';

const BlockOverflowCarousel = ({
    __typename,
    slides,
    timerDuration,
    isContinuous,
    extendedTopPadding,
    anchorId,
    flushTop,
    flushBottom,
}) => {
    timerDuration ??= 7;
    flushTop ??= false;
    flushBottom ??= false;

    return (
        <div
            className={cx(styles.blockRoot, {
                [styles.isExtendedTop]: extendedTopPadding,
                [styles.flushTop]: flushTop,
                [styles.flushBottom]: flushBottom,
            })}
            {...blockRootProps(anchorId, __typename)}
        >
            <CarouselProvider
                slides={slides}
                timerDuration={timerDuration}
                isContinuous={isContinuous}
            >
                <OverflowCarousel />
            </CarouselProvider>
        </div>
    );
};

BlockOverflowCarousel.propTypes = {
    __typename: PropTypes.string,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
    isContinuous: PropTypes.bool,
    extendedTopPadding: PropTypes.bool,
    anchorId: PropTypes.string,
    flushTop: PropTypes.bool,
    flushBottom: PropTypes.bool,
};

export default BlockOverflowCarousel;
