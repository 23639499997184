import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import useWait from 'hooks/useWait';

import ContactContext from '../../Context';

import styles from './Card.module.scss';

const variants = {
    visible: custom => ({
        opacity: 1,
        scale: 1,
        transition: { delay: custom * 0.1 },
    }),
    hidden: custom => ({
        opacity: 0,
        scale: 0.75,
        transition: { delay: custom * 0.1 },
    }),
};

const Card = ({ onClick, image, index, className, children, ...props }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const cc = useContext(ContactContext);
    const { timingBase } = cc;
    const wait = useWait();

    const handleClick = e => {
        setActiveIndex(index);

        onClick(e);

        wait(timingBase).then(() => {
            setActiveIndex(null);
        });
    };

    const label = typeof children === 'string' ? children : '';

    return (
        <button
            className={styles.root}
            onClick={handleClick}
            role="radio"
            aria-checked={false}
            aria-label={label}
            {...props}
        >
            <div className={styles.content}>
                <m.div
                    custom={index}
                    variants={variants}
                    className={cx(className, {
                        [styles.isClicked]: activeIndex === index,
                    })}
                >
                    {image && (
                        <Img
                            className={styles.image}
                            src={image}
                            fallbackImageWidth={500}
                            alt={image.description || label}
                        />
                    )}
                    <Text
                        className={styles.label}
                        baseTheme="bodySmall"
                        themes={{
                            medium: 'headingSmall',
                            xLarge: 'headingMedium',
                        }}
                    >
                        {children}
                    </Text>
                </m.div>
            </div>
        </button>
    );
};

Card.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    image: PropTypes.string,
    index: PropTypes.number,
    onClick: PropTypes.func,
};

export default Card;
