import React from 'react';

import cx from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ label, className, onClick, isHidden, tabIndex }) => {
    return (
        <AnimatePresence>
            {!isHidden && (
                <m.button
                    key={label || null}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0 } }}
                    transition={{ duration: 0.5, delay: 0.25 }}
                    className={cx(styles.root, className, {
                        [styles.isHidden]: isHidden,
                    })}
                    onClick={onClick}
                    tabIndex={tabIndex}
                >
                    <SvgIcon className={styles.icon} type="chevronLeft" />{' '}
                    <Text
                        className={styles.label}
                        baseTheme="bodySmall"
                        themes={{ medium: 'bodyMedium' }}
                    >
                        {label}
                    </Text>
                </m.button>
            )}
        </AnimatePresence>
    );
};

Breadcrumbs.propTypes = {
    className: PropTypes.string,
    isHidden: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    step: PropTypes.number,
    tabIndex: PropTypes.number,
};

export default Breadcrumbs;
