import React, { useContext, useEffect, useState } from 'react';
import { useMeasure, usePrevious } from 'react-use';

import cx from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { useTheme } from 'hooks/useTheme';
import useWait from 'hooks/useWait';

import ContactContext from '../Context';

import styles from './Accordion.module.scss';

const TextThemeMotion = m.create(Text.Theme);
const Accordion = ({
    title,
    children,
    index,
    pagination,
    exit,
    enter,
    idle,
}) => {
    const [isOpen, setOpen] = useState(false);
    const [theme] = useTheme();

    const { timingBase, activeStep, handleReset, setActiveStep } =
        useContext(ContactContext);

    const wait = useWait();

    const [renderButtonTitle, setRenderButtonTitle] = useState(title);
    const previousTitle = usePrevious(title);

    const [renderPanelChildren, setRenderPanelChildren] = useState(children);
    const previousChildren = usePrevious(children);

    const [heightRef, { height }] = useMeasure();

    const isActive = activeStep === index;

    const handleToggleClick = i => {
        if (i === 0) {
            handleReset();
        } else {
            setActiveStep(i);
        }
    };

    useEffect(() => {
        setOpen(isActive);
    }, [isActive]);

    useEffect(() => {
        if (title === previousTitle) return;

        wait(timingBase).then(() => {
            setRenderButtonTitle(title);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, previousTitle]);

    useEffect(() => {
        if (children === previousChildren) return;

        wait(timingBase).then(() => {
            setRenderPanelChildren(children);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, previousChildren]);

    const isPrevious = index < activeStep;
    const isUpcoming = index >= activeStep;

    return (
        <div
            className={cx(styles.accordion, styles[`theme--${theme}`], {
                [styles.enter]: enter,
                [styles.exit]: exit,
                [styles.idle]: idle,
                [styles.previous]: isPrevious,
                [styles.upcoming]: isUpcoming,
            })}
        >
            <div className={styles.accordionInner}>
                <AnimatePresence>
                    <m.button
                        key={title !== previousTitle}
                        initial={{ opacity: 0, transition: { delay: 0 } }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { delay: 0 } }}
                        transition={{ duration: 2, delay: 1 }}
                        onClick={() => handleToggleClick(index)}
                        className={styles.accordionToggle}
                    >
                        {pagination && (
                            <Text.Theme
                                className={styles.pagination}
                                config={{
                                    default: {
                                        baseTheme: 'labelMedium',
                                        themes: { large: 'labelLarge' },
                                    },
                                }}
                            >
                                {pagination}
                            </Text.Theme>
                        )}
                        <AnimatePresence mode="wait">
                            <TextThemeMotion
                                className={styles.label}
                                key={renderButtonTitle}
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                }}
                                exit={{
                                    opacity: 0,
                                }}
                                transition={{ duration: 0.5, delay: 0 }}
                                config={{
                                    default: {
                                        baseTheme: 'labelMedium',
                                        themes: { large: 'labelLarge' },
                                    },
                                }}
                            >
                                {renderButtonTitle}
                            </TextThemeMotion>
                        </AnimatePresence>
                    </m.button>
                </AnimatePresence>

                <div
                    className={cx(styles.panel, {
                        [styles.open]: isOpen,
                        [styles.close]: !isOpen,
                    })}
                    style={{ height: isOpen ? height : 0 }}
                >
                    <div className={styles.panelInner}>
                        <div ref={heightRef} className={styles.panelContent}>
                            {renderPanelChildren}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    activeStep: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    index: PropTypes.number,
    isActive: PropTypes.bool,
    pagination: PropTypes.string,
    setActiveStep: PropTypes.func,
    title: PropTypes.string,
    handleReset: PropTypes.func,
    exit: PropTypes.bool,
    enter: PropTypes.bool,
    idle: PropTypes.bool,
};

export default Accordion;
